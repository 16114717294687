import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './Page404.css';

export const Page404 = () => {
    return (
        <>
            <div className="container conta">
                <h1 className="title">404!</h1>
                <p className="message">Oops! Page is Under the Construction.</p>
                <Link to='/' style={{ textDecoration: 'none' }}>
                    <Button variant="outline-dark" className="home-button">Back to Home</Button>
                </Link>
            </div>
        </>
    );
}

export default Page404;