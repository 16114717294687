import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';
import Home from './Pages/Home/Home';
import Gallary from './Pages/Gallary/Gallary';
import About from './Pages/About/About';
import Products from './Pages/Products/Products';
import Career from './Pages/Career/Career';
import Contact from './Pages/ContactUs/Contact';
import ErrorPage from "./Pages/Page404/Page404"; 

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<ErrorPage />}>
      <Route index  element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="gallary" element={<Gallary />} />
      <Route path="gallary" element={<Gallary />} />
      <Route path="products" element={<Products />} />
      <Route path="Career" element={<Career />} />
      <Route path="contact" element={<Contact />} />
    </Route>
  )
);

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);